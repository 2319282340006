import React, { Component }  from 'react';
import cx from 'classnames';
import Styles from '../../assets/styles/brokerSearchPopup.module.css';
// import BrokerFinderSearch from "../../components/brokers/brokerFinderSearch";

class BrokerSearchPopup extends Component {
    constructor(props){
       super(props)    
   }

   closeSearch(e) {   
    
    this.props.closeSearchBrokerBox(false);

  }
render(){

     const openCloseSearchBoxValue = this.props.openCloseSearchBoxVal ? {} : {display:'none'};
  return(
        <div className={Styles.searchBrokerPopup}>           
        <div id={'Searchmodal'} className={cx('modal', Styles.searchBrokerModel,Styles.modal)} style={openCloseSearchBoxValue}>
          <div className={cx('modal-dialog', Styles.modalDialog)}>
            <div className={cx('modal-content', Styles.modalContent)}>
            <button onClick={this.closeSearch.bind(this)} type="button" className={cx('close', Styles.close)}>&times;</button>
              <div className={cx('modal-body', Styles.modalBody)}>
              {this.props.children}
              </div>
            </div>
          </div>
        </div>
        </div>
  );
}}


export default BrokerSearchPopup;

