import React from 'react';
import cx from 'classnames';
import Styles from '../../assets/styles/brokrFinderOtherinfo.module.css';



const BrokrFinderOtherinfo = props => {
	const heading = props.contentHeading,
	description =props.contentDescription;
	if(!heading || !description)
	{
		return (<div></div>)
	}
    return (

        <div className={cx(Styles.brokrFinderOtherinfo, 'col-12 text-center')}>
            <h4>{heading}</h4>
            <h5><span dangerouslySetInnerHTML={{ __html: description }}></span></h5>
        </div>

    )
};

export default BrokrFinderOtherinfo;

