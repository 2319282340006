import React, { Component } from 'react';
import Styles from '../../assets/styles/brokerFinderV3.module.css';
import cx from 'classnames';
import BrokerCard from "../../components/brokers/brokerCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class BrokerFinderTopRated extends Component {
  constructor(props) {

    super(props)
    this.cardClickHandler = this.cardClickHandler.bind(this)
  }
  cardClickHandler() {
    return false;
  }

  openSearchBox(e) {
    this.props.openCloseSearchBox(true);
  }

  render() {
    //console.log(JSON.stringify(this.props.data)); alert("check");

    let data = this.props.data.allHashchingBroker.edges;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      // variableWidth: true,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2
            // infinite: true,
            // dots: true
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
            // initialSlide: 2
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <section className={Styles.topBrokrs} >
        <div className={cx('container', Styles.customcontainer)}>
          <div className={'row'}>
            <h3 className={cx(Styles.sectionTitle, 'col-12')}>
              {/* {this.props.data.allKenticoCloudItemPageDefaultbrokerfinder.edges[0].node.elements.label_topbrokers.value} */}
              Top Rated Brokers
            </h3>
            <div className={cx(Styles.topBrokrsList, 'col-12')} onClick={this.openSearchBox.bind(this)}>

              {typeof window != 'undefined' && <Slider {...settings}>
                {
                  data.map(({ node }) =>
                    <div className={'row'} key={node.broker_id}>
                      <BrokerCard key={node.broker_id} brokerCardclick={this.cardClickHandler} card={node} nodeCountClass={'col-12 brokrdefaultCard'} />
                    </div>
                  )
                }
              </Slider>}
            </div>

          </div>
        </div>
      </section>
    );
  }


};

export default BrokerFinderTopRated;