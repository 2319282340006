import React from 'react'
import Styles from '../assets/styles/pageSummry.module.css';

const PageSummry = props => {
  const summary1 = props.summary1;
  const summary2 = props.summary2;
   return (
   		<p className={Styles.pageSummry}>
<span>HFG Marketplace's Mortgage Broker Finder helps you find a top-rated broker closest to you. Access 100% obligation free pre-negotiated home loan deals.</span>
   		</p> 

     );
};



export default PageSummry;