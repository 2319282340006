

import React from 'react'
import cx from 'classnames';
import Styles from '../assets/styles/howItWorksSteps.module.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(faCheck)

const HowItWorksSteps = props => { 
  const steps = props.steps;
  const labelheading = props.heading
  if(!steps || !labelheading) {
    return null;
  }
 
  return ( <section className={Styles.howitworksLoandealprocessSection}>
          <div className={'container'}>
            <div className={'row'}>
            <div className={cx('col-sm-12 d-flex', Styles.loandealprocessInnr)}>
              <div className={Styles.sectionHeadingMain}>
                <h4 className={Styles.sectionHeading}>{labelheading.label}</h4>
              </div>
                <div className={Styles.loandealProcess}>
                  <div className={cx('d-flex justify-content-center', Styles.bsWizard)}>
                   {steps.map((step,index) => (
                    <div key={index} className={cx(Styles.bsWizardStep, Styles[step.className])}>
                      <div className={'row'}>
                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-2', Styles.stepCount)}>
                          <div className={Styles.progress}>

                          </div>
                           <span className={cx(Styles.progressJoint)}></span>
                          <div className={Styles.bsWizardDot}>
                            <span className={Styles.number}>
                              <small className={Styles.count}>
                                {index+1}
                                                    </small></span>
                            <span className={Styles.tick}>
                              <small className={Styles.chkicon}>
                                <i className={cx(Styles.fa, Styles.faCheck)}>
                                <FontAwesomeIcon icon={faCheck}/>
                                </i>
                              </small></span>
                          </div>
                        </div>
                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-10', Styles.stepText)}>
                          <div className={Styles.bsWizardStepnum}><span dangerouslySetInnerHTML={{ __html: step.labelText }}></span></div>
                        </div>

                      </div>
                    </div>
                    ))
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  );

};

export default HowItWorksSteps;